
.fade-enter {
  max-height: 0;
  opacity: 0;
}

.fade-enter-active {
  max-height: 30px;
  opacity: 1;
  transition: all 500ms;
}

.fade-exit {
  max-height: 30px;
  opacity: 1;
}

.fade-exit-active {
  max-height: 0;
  opacity: 0;
  transition: all 500ms;
}

.element {
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
  animation-play-state: running;
  border-radius: 5px;
}


@keyframes stretch {
  0% {
      transform: scale(1);
      box-shadow: 0 0 0 .1rem RGBA(186, 128, 54, .3);
  }
  100% {
    transform: scale(1.01);
    box-shadow: 0 0 0 1rem RGBA(255, 255, 255, .1);
  }
}


